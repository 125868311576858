import { EventsTypeEnum } from '__generated__/types';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space } from 'antd';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { LABELS_MAP } from 'pages/CalendarPage/CalendarPage.const';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import s from './CustomHeader.module.less';

interface Props {
  date: Moment;
  onAddActionClick: () => void;
  filters: EventsTypeEnum[];
  onFiltersChange: (filters: EventsTypeEnum[]) => void;
  setCurrentDate: (date: Moment) => void;
}

const MONTHS_TRANSLATION_KEYS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const CustomHeader: FC<Props> = ({ date, onAddActionClick, onFiltersChange, filters, setCurrentDate }) => {
  const { t } = useTranslation();
  const year = date.year();

  return (
    <>
      <div className={s.container}>
        <div className={s.titleContainer}>
          <div className={s.title}>
            {t(`calendar.months.${MONTHS_TRANSLATION_KEYS[date.month()]}`)} {year}
          </div>
          <Space>
            <Button onClick={() => setCurrentDate(moment())}>{t('calendar.today')}</Button>
            <Space className={s.changeMonthBtnCnt}>
              <Button
                type="default"
                icon={<LeftOutlined />}
                onClick={() => setCurrentDate(moment(date).subtract(1, 'month'))}
              />
              <Button
                type="default"
                icon={<RightOutlined />}
                onClick={() => setCurrentDate(moment(date).add(1, 'month'))}
              />
            </Space>
            <Button className={s.addActionBtn} type="primary" icon={<PlusOutlined />} onClick={onAddActionClick} />
          </Space>
        </div>
        <div className={s.filters}>
          <Checkbox.Group value={filters} onChange={(list) => onFiltersChange(list as EventsTypeEnum[])}>
            <Checkbox value={EventsTypeEnum.PoLeadtimeEvent}>{LABELS_MAP[EventsTypeEnum.PoLeadtimeEvent](t)}</Checkbox>
            <Checkbox value={EventsTypeEnum.ProductionEvent} className={s.productionHolidaysCheckbox}>
              {LABELS_MAP[EventsTypeEnum.ProductionEvent](t)}
            </Checkbox>
            <Checkbox value={EventsTypeEnum.PublicHolidayEvent} className={s.publicHolidaysCheckbox}>
              {LABELS_MAP[EventsTypeEnum.PublicHolidayEvent](t)}
            </Checkbox>
          </Checkbox.Group>
        </div>
      </div>
    </>
  );
};
