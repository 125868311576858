import { useInitialURLHash } from 'common/hooks/useInitialURLHash';
import { useMissingI18nKeysReporter } from 'common/hooks/useMissingI18nKeysReporter';
import React, { PropsWithChildren } from 'react';

export const Middleware: React.FC<PropsWithChildren> = ({ children }) => {
  useInitialURLHash();
  useMissingI18nKeysReporter();

  return <>{children}</>;
};
