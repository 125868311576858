export enum LocaleEnum {
  EN = 'en',
  TR = 'tr',
  DE = 'de',
  ES = 'es',
  PL = 'pl',
  FR = 'fr',
  IT = 'it',
  EL = 'el',
  BG = 'bg',
  RO = 'ro',
  HU = 'hu',
  CN = 'cn',
}
