import { CurrencyEnum } from '__generated__/types';
import { LocaleEnum } from 'common/config/locales';
import { FunctionComponent, SVGProps } from 'react';

export enum Regions {
  EU = 'EU',
  TR = 'TR',
  UK = 'UK',
  INDIA = 'INDIA',
}

export interface RegionData<Region extends Regions = Regions> {
  region: Region;
  currency: CurrencyEnum;
  defaultLocale: LocaleEnum;
  supportedLocales: LocaleEnum[];
  supportEmail: string;
  supportPhone: string;
  productionSupportEmail: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  host: string;
}

export type RegionsData = { [K in Regions]: RegionData<K> };
