import { CURRENT_REGION } from 'common/config/regions/config';
import routes from 'common/routes';
import LogoSource from 'images/logo.svg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import s from './HeaderLogo.module.less';

export const HeaderLogo: FC = () => {
  const { t } = useTranslation();

  return (
    <Link to={routes.root} className={s.link}>
      <img className={s.img} src={LogoSource} alt="logo" />
      &nbsp;for Partners, {t(`regions.${CURRENT_REGION.toLowerCase()}.full`)}
    </Link>
  );
};
