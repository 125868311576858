import { useMonitoringClient } from '@xometry/ui';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Checks for missing keys and interpolations in i18n
 * and sends an error to the monitoring system.
 */
export const useMissingI18nKeysReporter = () => {
  const { i18n } = useTranslation();
  const monitoring = useMonitoringClient();
  const missingKeys = useRef<Record<string, boolean>>({});
  const missingInterpolations = useRef<Record<string, boolean>>({});

  useEffect(() => {
    if (!i18n || !monitoring) {
      return;
    }

    i18n.options.saveMissing = true;
    i18n.options.missingKeyHandler = (lngs, ns, key, _fb, _options) => {
      const lng = i18n?.language;
      const lngStore = (i18n?.store?.data || {})[lng] || {};
      const hasResource = Object.keys(lngStore[ns] || {}).length > 0;

      if (!hasResource) {
        return;
      }

      if (missingKeys.current[key]) {
        return;
      }

      missingKeys.current[key] = true;
      monitoring.captureError(new Error(`i18n: missing translation: [${key}]`), {
        level: 'warning',
        extra: { lngs, ns, key },
      });
    };

    i18n.options.missingInterpolationHandler = (text: string, value?: Array<string>) => {
      const [interpolation] = value || [];
      const key = interpolation ? `${interpolation}${text}` : text;

      if (missingInterpolations.current[key]) {
        return;
      }

      missingInterpolations.current[key] = true;

      const interpolationText = ['interpolation', interpolation].filter(Boolean).join(' ');
      monitoring.captureError(new Error(`i18n: missing ${interpolationText} in [${text}]`), { level: 'warning' });
    };
  }, [i18n, monitoring]);
};
