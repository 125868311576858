import { QuestionCircleOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { amplitudeLogger, FAQ_CLICK } from 'common/amplitude';
import { LocaleEnum } from 'common/config/locales';
import { ENABLE_FINANCE_PAGE } from 'common/config/regions/features';
import { getRegionSupportedLocales } from 'common/config/regions/utils';
import { regionLinks } from 'common/constants/links';
import { DeviceTypes } from 'common/enums/deviceTypes';
import { useDeviceTypeByWindowSize } from 'common/hooks/useDeviceType';
import { ContactType } from 'common/queries/types';
import routes from 'common/routes';
import { HeaderMenuSeparator } from 'components/Header/HeaderMenuSeparator/HeaderMenuSeparator';
import { langIcons } from 'components/LanguageChanger/LanguageChanger.const';
import { MessengerHeaderButton } from 'components/Messenger/MessengerHeaderButton/MessengerHeaderButton';
import { ReactComponent as UserIcon } from 'images/header/person.svg';
import { ReactComponent as IconOutlinedLogout } from 'images/outlined-logout.svg';
import { ReactComponent as IconCheck } from 'images/solid-check.svg';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import styles from './HeaderMenu.module.less';

interface Props {
  contact: ContactType;
  onLogout: () => void;
  onLocaleChange: (newLocale: LocaleEnum) => void;
}

const ProfileSubmenuKey = 'profile-submenu';
const CheckedIcon = <IconCheck className={styles.checkedIcon} />;

const routeKeys = [
  routes.profileOnboarding,
  routes.offers,
  routes.orders,
  routes.profileDetails,
  routes.support,
  routes.finance,
  routes.calendar,
];
export const getSelectedKey = (pathName: string) =>
  routeKeys.reduce((acc: string[], item) => {
    if (pathName.includes(item)) {
      acc.push(item);
    }

    return acc;
  }, []);

export const HeaderMenu: FC<Props> = ({ contact, onLogout, onLocaleChange }) => {
  const { t } = useTranslation();
  const deviceType = useDeviceTypeByWindowSize();
  const isDesktop = deviceType === DeviceTypes.DESKTOP;
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const languagesList = getRegionSupportedLocales();
  const location = useLocation();
  const selectedKeys = useMemo(() => getSelectedKey(location.pathname), [location.pathname]);

  const ProfileMenu = useMemo(() => {
    const list: MenuProps['items'] = [
      {
        key: routes.profileDetails,
        icon: <UserOutlined />,
        label: <Link to={routes.profileDetails}>{t('header.menuItems.profile')}</Link>,
      },
      {
        key: routes.support,
        icon: <UnorderedListOutlined />,
        label: <Link to={routes.support}>{t('header.menuItems.support')}</Link>,
      },
      {
        key: 'faq',
        icon: <QuestionCircleOutlined />,
        label: (
          <a
            href={regionLinks.knowledgeBase}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => amplitudeLogger(FAQ_CLICK)}
          >
            {t('header.menuItems.faqAndHelp')}
          </a>
        ),
      },
      {
        key: 'locales',
        icon: langIcons[currentLocale],
        label: <span>{currentLocale.toUpperCase()}</span>,
        popupClassName: styles.popup,
        children: languagesList.map((l) => {
          const key = `locale_${l}`;
          const selected = l === currentLocale;
          const icon = langIcons[l];
          const label = <span>{l.toUpperCase()}</span>;
          const itemIcon = selected ? CheckedIcon : undefined;
          const onClick = () => onLocaleChange(l);

          return {
            key,
            icon,
            label,
            itemIcon,
            onClick,
          };
        }),
      },
      {
        type: 'divider',
      },
    ];

    if (isDesktop) {
      list?.push({
        key: 'logout',
        icon: <IconOutlinedLogout />,
        label: (
          <Button type="link" onClick={() => void onLogout()} className={styles.logoutBtn}>
            {t('header.menuItems.logOut')}
          </Button>
        ),
      });
    }

    return list;
  }, [currentLocale, isDesktop, languagesList, onLocaleChange, onLogout, t]);

  const MenuItems = useMemo(() => {
    const list: MenuProps['items'] = [];

    if (!contact?.provider?.onboardingCompleted) {
      list.push({
        key: routes.profileOnboarding,
        label: <Link to={routes.profileOnboarding}>{t('header.menuItems.firstSteps')}</Link>,
      });
    }

    list.push({
      key: routes.offers,
      label: <Link to={routes.offers}>{t('header.menuItems.jobBoard')}</Link>,
    });

    list.push({
      key: routes.orders,
      label: <Link to={routes.orders}>{t('header.menuItems.myOrders')}</Link>,
    });

    if (ENABLE_FINANCE_PAGE) {
      list.push({
        key: routes.finance,
        label: <Link to={routes.finance}>{t('header.menuItems.finance')}</Link>,
      });
    }

    list.push({
      key: routes.calendar,
      label: <Link to={routes.calendar}>{t('header.menuItems.calendar')}</Link>,
    });

    if (isDesktop) {
      list.push({
        key: 'sep004',
        className: styles.separator,
        label: <HeaderMenuSeparator />,
      });
      list.push({ key: 'messenger', className: styles.messengerBtn, label: <MessengerHeaderButton /> });
    }

    list.push({
      key: ProfileSubmenuKey,
      icon: <UserIcon />,
      label: !isDesktop ? (
        <span>
          {contact.firstName} {contact.lastName}
        </span>
      ) : null,
      popupClassName: styles.popup,
      children: ProfileMenu,
    });

    if (!isDesktop) {
      list.push({
        key: 'logout',
        icon: <IconOutlinedLogout />,
        label: (
          <Button type="link" onClick={() => void onLogout()} className={styles.logoutBtn}>
            {t('header.menuItems.logOut')}
          </Button>
        ),
      });
    }

    return list;
  }, [
    ProfileMenu,
    contact.firstName,
    contact.lastName,
    contact?.provider?.onboardingCompleted,
    isDesktop,
    onLogout,
    t,
  ]);

  const menuMode: MenuProps['mode'] = !isDesktop ? 'inline' : 'horizontal';

  return (
    <Menu
      className={styles.mainMenu}
      theme="light"
      mode={menuMode}
      selectedKeys={selectedKeys}
      triggerSubMenuAction="click"
      items={MenuItems}
    />
  );
};
