import { IWindow } from 'common/interfaces';
import React, { FC, useEffect } from 'react';

const winInterface = window as IWindow;
interface IProps {
  contactId?: number;
}

const GoogleTags: FC<IProps> = ({ contactId }) => {
  useEffect(() => {
    if (!winInterface.dataLayer) {
      console.error("Can't set contact for dataLayer", contactId);

      return;
    }

    if (contactId) {
      winInterface.dataLayer.push({
        userId: contactId,
      });
    }
  }, [contactId]);

  return <></>;
};

export default GoogleTags;
