import en from 'antd/es/locale/en_US';
import tr from 'antd/es/locale/tr_TR';
import { Locale } from 'antd/lib/locale-provider';
import { CURRENT_REGION } from 'common/config/regions/config';
import { Regions } from 'common/config/regions/types';
import { DecisionState, ResponseState, SelectOption } from 'common/interfaces';
import { TagTypes } from 'components/Tag/Tag';
import { TFunction } from 'i18next';

export const DECISION_MAPPING: Record<DecisionState, { label: (t: TFunction) => string; tagType: TagTypes }> = {
  pending: {
    label: (t) => t('responses.statuses.waitingForDecision'),
    tagType: 'warning',
  },
  auto_accepted: {
    label: (t) => t('responses.statuses.acceptedByXometry'),
    tagType: 'success',
  },
  accepted: {
    label: (t) => t('responses.statuses.acceptedByXometry'),
    tagType: 'success',
  },
  rejected: {
    label: (t) => t('responses.statuses.rejectedByXometry'),
    tagType: 'error',
  },
  rejected_by_partner: {
    label: (t) => t('responses.statuses.canceledByPartner'),
    tagType: 'error',
  },
  irrelevant: {
    label: (t) => t('responses.statuses.irrelevant'),
    tagType: 'error',
  },
};

export const RESPONSE_MAPPING: Record<ResponseState, string> = {
  empty: '',
  application: 'commonPhrases.application',
  counteroffer: 'commonPhrases.counteroffer',
  irrelevant: 'commonPhrases.irrelevant',
};

const logisticOperatorsConfig: Record<Regions, SelectOption[]> = {
  [Regions.EU]: [
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'DPD',
      label: 'DPD',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'FedEx',
      label: 'FedEx',
    },
    {
      value: 'GLS',
      label: 'GLS',
    },
    {
      value: 'CargoInternational',
      label: 'CargoInternational',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'Deutsche Post',
      label: 'Deutsche Post',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'Euro Bus',
      label: 'Euro Bus',
    },
    {
      value: 'GEL',
      label: 'GEL',
    },
    {
      value: 'Go Express',
      label: 'Go Express',
    },
    {
      value: 'Hermes',
      label: 'Hermes',
    },
    {
      value: 'Jumingo Cargo',
      label: 'Jumingo Cargo',
    },
    {
      value: 'Jumingo same day',
      label: 'Jumingo same day',
    },
    {
      value: 'Kuehne + Nagel',
      label: 'Kuehne + Nagel',
    },
    {
      value: 'LKW',
      label: 'LKW',
    },
    {
      value: 'Aramex',
      label: 'Aramex',
    },
    {
      value: 'Meest',
      label: 'Meest',
    },
    {
      value: 'Air China Cargo',
      label: 'Air China Cargo',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
  [Regions.TR]: [
    { value: 'Yurtiçi', label: 'Yurtiçi' },
    { value: 'Kurye (Courier)', label: 'Kurye (Courier)' },
    { value: 'Elden Teslimat (Hand Delivery)', label: 'Elden Teslimat (Hand Delivery)' },
    { value: 'MNG Kargo', label: 'MNG Kargo' },
    { value: 'Sürat Kargo', label: 'Sürat Kargo' },
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'HubTic',
      label: 'HubTic',
    },
    {
      value: 'Millalog',
      label: 'Millalog',
    },
    {
      value: 'Aras',
      label: 'Aras Kargo',
    },
    {
      value: 'Tem Kurye',
      label: 'Tem Kurye',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'Banabi Kurye',
      label: 'Banabi Kurye',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
  [Regions.UK]: [
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'DPD',
      label: 'DPD',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'FedEx',
      label: 'FedEx',
    },
    {
      value: 'GLS',
      label: 'GLS',
    },
    {
      value: 'CargoInternational',
      label: 'CargoInternational',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'Deutsche Post',
      label: 'Deutsche Post',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'Euro Bus',
      label: 'Euro Bus',
    },
    {
      value: 'GEL',
      label: 'GEL',
    },
    {
      value: 'Go Express',
      label: 'Go Express',
    },
    {
      value: 'Hermes',
      label: 'Hermes',
    },
    {
      value: 'Jumingo Cargo',
      label: 'Jumingo Cargo',
    },
    {
      value: 'Jumingo same day',
      label: 'Jumingo same day',
    },
    {
      value: 'Kuehne + Nagel',
      label: 'Kuehne + Nagel',
    },
    {
      value: 'LKW',
      label: 'LKW',
    },
    {
      value: 'Aramex',
      label: 'Aramex',
    },
    {
      value: 'Meest',
      label: 'Meest',
    },
    {
      value: 'Air China Cargo',
      label: 'Air China Cargo',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
  [Regions.INDIA]: [
    {
      value: 'UPS',
      label: 'UPS',
    },
    {
      value: 'DHL',
      label: 'DHL',
    },
    {
      value: 'DPD',
      label: 'DPD',
    },
    {
      value: 'TNT',
      label: 'TNT',
    },
    {
      value: 'FedEx',
      label: 'FedEx',
    },
    {
      value: 'GLS',
      label: 'GLS',
    },
    {
      value: 'CargoInternational',
      label: 'CargoInternational',
    },
    {
      value: 'Cargoboard',
      label: 'Cargoboard',
    },
    {
      value: 'Deutsche Post',
      label: 'Deutsche Post',
    },
    {
      value: 'Direct',
      label: 'Direct',
    },
    {
      value: 'Euro Bus',
      label: 'Euro Bus',
    },
    {
      value: 'GEL',
      label: 'GEL',
    },
    {
      value: 'Go Express',
      label: 'Go Express',
    },
    {
      value: 'Hermes',
      label: 'Hermes',
    },
    {
      value: 'Jumingo Cargo',
      label: 'Jumingo Cargo',
    },
    {
      value: 'Jumingo same day',
      label: 'Jumingo same day',
    },
    {
      value: 'Kuehne + Nagel',
      label: 'Kuehne + Nagel',
    },
    {
      value: 'LKW',
      label: 'LKW',
    },
    {
      value: 'Aramex',
      label: 'Aramex',
    },
    {
      value: 'Meest',
      label: 'Meest',
    },
    {
      value: 'Air China Cargo',
      label: 'Air China Cargo',
    },
    {
      value: 'KDZ Express',
      label: 'KDZ Express',
    },
    {
      value: 'Other',
      label: 'other',
    },
  ],
};

export const PROVIDER_COUNTRIES_FOR_SELF_BILLING = ['Germany', 'China', 'India', 'Lithuania', 'Viet Nam'];

export const DEFAULT_PAGE_SIZE = 10;
export const REGULAR_MONTHLY_PAYMENTS_TAG = 'Regular Monthly Payments';

export const ANT_LOCALE_MAPPING: Record<string, Locale> = {
  tr: tr,
  en: en,
};

export const logisticOperators = logisticOperatorsConfig[CURRENT_REGION];
export const IS_EU = CURRENT_REGION === Regions.EU;
export const IS_TR = CURRENT_REGION === Regions.TR;
export const IS_UK = CURRENT_REGION === Regions.UK;
