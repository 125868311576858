import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessengerCounterQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MessengerCounterQuery = { __typename?: 'Query', messengerCounter: { __typename?: 'Counter', unreadMessages: number, sources: Array<{ __typename?: 'MessageSource', sourceId: number, sourceType: string, unreadMessagesCount: number }> } };


export const MessengerCounterDocument = gql`
    query messengerCounter {
  messengerCounter {
    sources {
      sourceId
      sourceType
      unreadMessagesCount
    }
    unreadMessages
  }
}
    `;

/**
 * __useMessengerCounterQuery__
 *
 * To run a query within a React component, call `useMessengerCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerCounterQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessengerCounterQuery(baseOptions?: Apollo.QueryHookOptions<MessengerCounterQuery, MessengerCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessengerCounterQuery, MessengerCounterQueryVariables>(MessengerCounterDocument, options);
      }
export function useMessengerCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessengerCounterQuery, MessengerCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessengerCounterQuery, MessengerCounterQueryVariables>(MessengerCounterDocument, options);
        }
export type MessengerCounterQueryHookResult = ReturnType<typeof useMessengerCounterQuery>;
export type MessengerCounterLazyQueryHookResult = ReturnType<typeof useMessengerCounterLazyQuery>;
export type MessengerCounterQueryResult = Apollo.QueryResult<MessengerCounterQuery, MessengerCounterQueryVariables>;