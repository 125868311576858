import { CODE_VERSION } from 'common/constants/env';
import { makeAbsoluteRoute } from 'common/routes';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { noop } from 'lodash-es';
import { initReactI18next } from 'react-i18next';

import { LocaleEnum } from './locales';
import { getRegionDefaultLocale, getRegionSupportedLocales } from './regions/utils';

export const getI18nClient = async () => {
  let lng: LocaleEnum = getRegionDefaultLocale();

  if (typeof window !== 'undefined') {
    try {
      const params = new URLSearchParams(window.location.search);
      const newLocale = String(params.get('locale') || '') as LocaleEnum;

      if (getRegionSupportedLocales().includes(newLocale)) {
        lng = newLocale;
      }
    } catch (_e: unknown) {
      // nothing
    }
  }

  // eslint-disable-next-line import/no-named-as-default-member
  await i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a'],
        nsMode: 'default',
      },
      detection: {
        order: ['htmlTag'],
      },
      backend: {
        loadPath: makeAbsoluteRoute(`/locales/{{lng}}.json?v=${CODE_VERSION}`),
      },
      lng,
      fallbackLng: LocaleEnum.EN,
      interpolation: {
        escapeValue: false,
      },
    })
    .catch(noop);

  return i18n;
};
