import { CURRENT_REGION } from 'common/config/regions/config';
import { Regions } from 'common/config/regions/types';
import { getSupportPhone } from 'common/config/regions/utils';
import { regionLinks } from 'common/constants/links';
import { TFunction } from 'i18next';

interface Address {
  street: string;
  city: string;
  country: string;
}

export const footerLinks = [
  {
    title: (t: TFunction) => t('footer.resourcesSection.termsAndConditions'),
    url: regionLinks.termsAndConditions,
  },
  {
    title: (t: TFunction) => t('footer.resourcesSection.NDA'),
    url: regionLinks.NDA,
  },
  {
    title: (t: TFunction) => t('footer.resourcesSection.privacyPolicy'),
    url: regionLinks.privacyPolicy,
  },
  {
    title: (t: TFunction) => t('footer.resourcesSection.contactsAndImprint'),
    url: regionLinks.contactsAndImprint,
  },
];

const companyNames: Record<Regions, string> = {
  [Regions.EU]: 'Xometry Europe GmbH',
  [Regions.TR]: 'XOMETRY TR TEKNOLOJİ ANONİM ŞİRKETİ',
  [Regions.UK]: 'Xometry UK Ltd',
  [Regions.INDIA]: 'Xometry India',
};

const addressesConfig: Record<Regions, Address> = {
  [Regions.EU]: {
    street: 'Ada-Lovelace-Str. 9',
    city: '85521 Ottobrunn',
    country: 'Germany',
  },
  [Regions.TR]: {
    street: 'YEŞİLBAĞLAR MAH. D100 BLV. NO:20B',
    city: 'PENDİK/İSTANBUL',
    country: 'Türkiye',
  },
  [Regions.UK]: {
    street: 'Unit 2 Park Road, City Park West',
    city: 'Chelmsford, Essex, CM1 1HF',
    country: 'England',
  },
  [Regions.INDIA]: {
    street: 'Unit 2 Park Road, City Park West',
    city: 'Chelmsford, Essex, CM1 1HF',
    country: 'England',
  },
};

const phonesConfig: Record<Regions, string[]> = {
  [Regions.EU]: [getSupportPhone(Regions.EU)],
  [Regions.TR]: [getSupportPhone(Regions.TR)],
  [Regions.UK]: [getSupportPhone(Regions.UK)],
  [Regions.INDIA]: [getSupportPhone(Regions.INDIA)],
};

export const companyName = companyNames[CURRENT_REGION];
export const address = addressesConfig[CURRENT_REGION];
export const phones = phonesConfig[CURRENT_REGION];
