import { EMessengerScreenName, IMessengerChatSource } from '@xometry/ui';
import { useOverlayContainerContext } from 'components/OverlayContainer/OverlayContainer.hooks';
import { EOverlayType } from 'components/OverlayContainer/OverlayContainerContext.types';
import { useCallback } from 'react';

export const useOpenMessenger = () => {
  const { openSideOverlay } = useOverlayContainerContext();

  const openMessenger = useCallback(
    (chatSource: IMessengerChatSource) => {
      openSideOverlay({
        type: EOverlayType.Messenger,
        initialScreen: {
          name: EMessengerScreenName.CHAT,
          params: {
            chatSource,
          },
        },
      });
    },
    [openSideOverlay],
  );

  return openMessenger;
};
