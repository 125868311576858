import { getDeviceTypeByScreenSize } from 'common/helpers/getDeviceTypeByScreenSize';
import { getDeviceTypeByWindowSize } from 'common/helpers/getDeviceTypeByWindowSize';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

/**
 * Determines the device type by the width of the browser window.
 */
export const useDeviceTypeByWindowSize = () => {
  const [deviceType, setDeviceType] = useState(getDeviceTypeByWindowSize());

  useEffect(() => {
    const handleResize = debounce(() => setDeviceType(getDeviceTypeByWindowSize()), 100);
    window?.addEventListener('resize', handleResize);
    handleResize();

    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  return deviceType;
};

/**
 * Determines the device type by the width of the device screen.
 */
export const useDeviceTypeByScreenSize = () => {
  const deviceType = useRef(getDeviceTypeByScreenSize());

  return deviceType.current;
};
