import { EMessengerEventBusEvents } from '@xometry/ui';
import { Menu } from 'antd';
import cx from 'classnames';
import { LocaleEnum } from 'common/config/locales';
import { CURRENT_REGION } from 'common/config/regions/config';
import { Regions } from 'common/config/regions/types';
import {
  getRegionDefaultLocale,
  getRegionHost,
  getRegionIcon,
  getRegionsList,
  getRegionSupportedLocales,
} from 'common/config/regions/utils';
import { useMessengerEventBus } from 'common/hooks/useMessengerEventBus';
import { useRouteQuery } from 'common/hooks/useRouteQuery/useRouteQuery';
import routes from 'common/routes';
import { t } from 'i18next';
import { ReactComponent as IconCheck } from 'images/solid-check.svg';
import qs from 'qs';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { langIcons } from './LanguageChanger.const';
import styles from './LanguageChanger.module.less';

interface LanguageChangerProps {
  hasContact?: boolean;
}

const CheckedIcon = <IconCheck className={styles.checkedIcon} />;
const RegionIcon = getRegionIcon();
const RegionsList = getRegionsList();
export const LanguageChanger: FC<LanguageChangerProps> = ({ hasContact }) => {
  const { i18n } = useTranslation();
  const { sendEvent } = useMessengerEventBus();
  const history = useHistory();
  const query = useRouteQuery();
  const locale = i18n.language as LocaleEnum;
  const isSignUpPage = window.location.href.includes(routes.profileSignUp);
  const languagesList = isSignUpPage ? Object.values(LocaleEnum) : getRegionSupportedLocales();

  const regionTitle = t(`regions.${CURRENT_REGION.toLowerCase()}.short`);

  const langMenuTitle = (
    <div className={styles.langMenuTitle}>
      <span>{regionTitle}</span>
      <span className={styles.currentLocale}>{` | ${locale.toUpperCase()}`}</span>
    </div>
  );

  const handleChangeLocale = useCallback(
    (newLang: LocaleEnum | string) => {
      void i18n.changeLanguage(newLang);
      localStorage.setItem('currentLanguage', newLang);
      const data = qs.parse(location.search.replace('?', ''));
      history.push({
        search: `?${qs.stringify({ ...data, locale: newLang })}`,
      });
      sendEvent?.(EMessengerEventBusEvents.CHANGE_LANGUAGE, { newLocale: newLang });
    },
    [history, i18n, sendEvent],
  );

  const handleChangeRegion = useCallback((region: Regions) => {
    if (region === CURRENT_REGION) {
      return;
    }

    const link = getRegionHost(region);
    const path = location.pathname;

    window.location.href = `${link}${path}`;
  }, []);

  useEffect(() => {
    const newLocale = query.get('locale') || localStorage.getItem('currentLanguage') || getRegionDefaultLocale();
    handleChangeLocale(newLocale);
  }, [handleChangeLocale, query]);

  return (
    <Menu className={styles.container} theme="light" mode="horizontal" triggerSubMenuAction="click">
      <Menu.SubMenu
        key="SubMenu"
        icon={<RegionIcon className={styles.regionIcon} />}
        title={langMenuTitle}
        className={cx('lang-submenu', styles.subMenu)}
        popupClassName="langPopupMenu"
      >
        {!hasContact ? (
          <>
            <Menu.Item key="regionsHeader" className={styles.regionsListHeader}>
              Region:
            </Menu.Item>
            {RegionsList.map((region, i, list) => {
              const key = `region_${region}`;
              const Icon = getRegionIcon(region);
              const ItemIcon = CURRENT_REGION === region ? CheckedIcon : undefined;
              const onClick = () => handleChangeRegion(region);
              const text = t(`regions.${region.toLowerCase()}.full`);

              const classes = cx({
                [styles.menuItem]: true,
                [styles.lastRegionMenuItem]: i === list.length - 1,
              });

              return (
                <Menu.Item className={classes} key={key} icon={<Icon />} itemIcon={ItemIcon} onClick={onClick}>
                  {text}
                </Menu.Item>
              );
            })}
          </>
        ) : null}
        <>
          <Menu.Item key="localesHeader" className={styles.localesListHeader}>
            {`${t('commonPhrases.language')}:`}
          </Menu.Item>
          {languagesList.map((l) => (
            <Menu.Item
              key={l}
              className={styles.menuItem}
              icon={langIcons[l]}
              itemIcon={l === locale ? CheckedIcon : undefined}
              onClick={() => handleChangeLocale(l)}
            >
              {t(`languages.${l}`)}
            </Menu.Item>
          ))}
        </>
      </Menu.SubMenu>
    </Menu>
  );
};
