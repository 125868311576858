import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type ProviderFieldsFragment = { __typename?: 'Provider', activeChangeRequestId?: number | null, id: number, state: string, competenceFilled?: boolean | null, onboardingCompleted?: boolean | null, publicComment?: string | null, primaryContactId?: number | null, name: string, legalName?: string | null, email?: string | null, vatId?: string | null, notVatPayer: boolean, phone?: string | null, website?: string | null, pincode?: string | null, locale?: string | null, bankAccount?: string | null, bankName?: string | null, bankCode?: string | null, bankAccountHolderName?: string | null, bankCountry?: string | null, isEuropean?: boolean | null, isLocal?: boolean | null, isRfqReady?: boolean | null, poNumberLimit?: number | null, poValueLimit?: number | null, acceptSelfBilling: boolean, freeCapacity: boolean, freeCapacityFreezeUntil?: string | null, financeCheckAt?: string | null, fileUuidIds?: Array<string> | null, limitsComment?: string | null, gstIn?: string | null };

export const ProviderFieldsFragmentDoc = gql`
    fragment providerFields on Provider {
  activeChangeRequestId
  id
  state
  competenceFilled
  onboardingCompleted
  publicComment
  primaryContactId
  name
  legalName
  email
  vatId
  notVatPayer
  phone
  website
  pincode
  locale
  bankAccount
  bankName
  bankCode
  bankAccountHolderName
  bankCountry
  isEuropean
  isLocal
  isRfqReady
  poNumberLimit
  poValueLimit
  acceptSelfBilling
  freeCapacity
  freeCapacityFreezeUntil
  financeCheckAt
  fileUuidIds
  limitsComment
  gstIn
}
    `;