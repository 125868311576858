import generatePicker, { PickerProps } from 'antd/es/date-picker/generatePicker';
import { useMoment } from 'common/hooks/useMoment';
import { Moment } from 'moment';
import { GenerateConfig } from 'rc-picker/es/generate';
import momentConfig from 'rc-picker/lib/generate/moment';
import React, { forwardRef } from 'react';

export const CustomDatePicker = forwardRef<unknown, PickerProps<Moment>>((props, ref) => {
  useMoment();
  const Picker = generatePicker(momentConfig as GenerateConfig<Moment>);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return <Picker ref={ref} {...props} />;
});

CustomDatePicker.displayName = 'CustomDatePicker';
