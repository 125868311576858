import { clearStorage, setAuthToken, setRefreshToken } from 'common/requests';
import routes from 'common/routes';
import qs from 'qs';
import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// USE: http://partners.local.xometry.de:3000/new_pool?redirect_to=/offers/urgent
export const RemoteSignIn: FC = () => {
  const { token } = useParams<{ token: string }>();
  const data = qs.parse(location.search.replace('?', ''));
  const history = useHistory();

  useEffect(
    () => {
      clearStorage();

      if (token) {
        setAuthToken(token);
      }

      if (data['refresh_token']) {
        setRefreshToken(data['refresh_token'] as string);
      }

      if (data['redirect_to']) {
        history.push(data['redirect_to'] as string);
      } else {
        history.push(routes.root);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  );

  return <></>;
};
