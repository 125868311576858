import { EMessengerEventBusEvents } from '@xometry/ui';
import { amplitudeLogger, LOGOUT_CLICK } from 'common/amplitude';
import { LocaleEnum } from 'common/config/locales';
import { getRegionDefaultLocale } from 'common/config/regions/utils';
import { DeviceTypes } from 'common/enums/deviceTypes';
import { useDeviceTypeByWindowSize } from 'common/hooks/useDeviceType';
import { useMessengerEventBus } from 'common/hooks/useMessengerEventBus';
import { useRouteQuery } from 'common/hooks/useRouteQuery/useRouteQuery';
import { useContactsSignOutMutation } from 'common/mutations/__generated__/contactsSignOut';
import { ContactType } from 'common/queries/types';
import { clearAuthToken, clearRefreshToken, redirectToRoot } from 'common/requests';
import { HeaderLogo } from 'components/Header/HeaderLogo';
import { MessengerHeaderButton } from 'components/Messenger/MessengerHeaderButton/MessengerHeaderButton';
import qs from 'qs';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BurgerMenuWrapper } from './BurgerMenuWrapper/BurgerMenuWrapper';
import { HeaderMenu } from './HeaderMenu';
import styles from './PrivateHeader.module.less';

interface AuthHeaderProps {
  contact: ContactType;
}

export const PrivateHeader: FC<AuthHeaderProps> = ({ contact }) => {
  const [userSignOut] = useContactsSignOutMutation();
  const deviceType = useDeviceTypeByWindowSize();
  const isDesktop = deviceType === DeviceTypes.DESKTOP;
  const { i18n } = useTranslation();
  const history = useHistory();
  const { sendEvent } = useMessengerEventBus();
  const query = useRouteQuery();

  const logout = async () => {
    amplitudeLogger(LOGOUT_CLICK);
    await userSignOut();
    clearAuthToken();
    clearRefreshToken();
    redirectToRoot();
  };

  const handleChangeLocale = useCallback(
    (newLang: LocaleEnum | string) => {
      void i18n.changeLanguage(newLang);
      localStorage.setItem('currentLanguage', newLang);
      const data = qs.parse(location.search.replace('?', ''));
      history.push({
        search: `?${qs.stringify({ ...data, locale: newLang })}`,
      });
      sendEvent?.(EMessengerEventBusEvents.CHANGE_LANGUAGE, { newLocale: newLang });
    },
    [history, i18n, sendEvent],
  );

  useEffect(() => {
    const newLocale = query.get('locale') || localStorage.getItem('currentLanguage') || getRegionDefaultLocale();
    handleChangeLocale(newLocale);
  }, [handleChangeLocale, query]);

  return (
    <header className={styles.container}>
      {!isDesktop ? (
        <BurgerMenuWrapper>
          {() => <HeaderMenu contact={contact} onLogout={() => void logout()} onLocaleChange={handleChangeLocale} />}
        </BurgerMenuWrapper>
      ) : null}
      <HeaderLogo />
      {!isDesktop ? (
        <div className={styles.messengerBtn}>
          <MessengerHeaderButton className={styles.mobileMessengerBtn} />
        </div>
      ) : (
        <HeaderMenu contact={contact} onLogout={() => void logout()} onLocaleChange={handleChangeLocale} />
      )}
    </header>
  );
};
