import { CurrencyEnum } from '__generated__/types';
import { LocaleEnum } from 'common/config/locales';
import { RegionData, Regions } from 'common/config/regions/types';
import { ENV } from 'common/constants/env';

import { ReactComponent as Icon } from './assets/uk.svg';

export const UKRegionConfig: RegionData<Regions.UK> = {
  region: Regions.UK,
  currency: 'GBP' as unknown as CurrencyEnum,
  defaultLocale: LocaleEnum.EN,
  supportedLocales: [LocaleEnum.EN],
  supportEmail: 'provider@xometry.uk',
  productionSupportEmail: 'production@xometry.uk',
  supportPhone: '+44-12-4584 0035',
  icon: Icon,
  host: ENV.UK_HOST,
};
