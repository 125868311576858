import cn from 'classnames';
import { getSupportEmail } from 'common/config/regions/utils';
import { MESSENGER_ENTRY_POINTS } from 'common/enums/amplitudeEvents';
import { toMailLink, toPhoneLink } from 'common/helpers/formtatters';
import { ContactContext, withAuth } from 'common/hoc';
import routes from 'common/routes';
import FacebookPixel from 'components/FacebookPixel';
import GoogleTags from 'components/GoogleTags';
import { GeneralChatButton } from 'components/Messenger/GeneralChatButton';
import { ReactComponent as Location } from 'images/location.svg';
import LogoLight from 'images/logo_light.svg';
import { ReactComponent as XLogo } from 'images/logo_x.svg';
import { ReactComponent as Phone } from 'images/phone.svg';
import moment from 'moment';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { address, companyName, phones } from './Footer.const';
import { resources } from './Footer.helpers';
import styles from './Footer.module.less';

const FooterComponent = () => {
  const contact = useContext(ContactContext);
  const location = useLocation();
  const isOnboardingPage = location.pathname === routes.profileOnboarding || !contact;
  const { t } = useTranslation();

  return (
    <footer
      className={cn(styles.container, {
        [styles.hasMargin]: !isOnboardingPage,
      })}
    >
      <div className={styles.content}>
        <section className={styles.company}>
          <div className={cn(styles.titleContainer, styles.left)}>
            <img className={styles.logo} src={LogoLight} alt="Xometry" />
            <span className={styles.title}>{t('footer.companySection.partnersArea')}</span>
          </div>
          <p className={styles.info}>
            © 2017-{moment().format('YYYY')}, {companyName}
            {contact && (
              <>
                <br />
                {t('footer.companySection.account')}: #{contact.provider.id} <br /> {t('footer.companySection.owner')}:{' '}
                {contact.firstName} {contact.lastName}
              </>
            )}
          </p>
        </section>
        <section className={styles.resources}>
          <div className={styles.titleContainer}>
            <XLogo />
          </div>
          <div className={styles.resourcesList}>
            {resources.map((item, key) => (
              <Fragment key={key}>
                <a
                  className={styles.link}
                  href={item.url ? item.url : undefined}
                  rel="noopener noreferrer"
                  target={!item.noTargetBlank ? '_blank' : ''}
                  onClick={item.onClick}
                >
                  {item.title(t)}
                </a>
              </Fragment>
            ))}
          </div>
        </section>
        <section className={styles.contacts}>
          <div className={styles.titleContainer}>
            <Phone />
          </div>
          <div className={styles.contactsList}>
            {phones.map((phone) => (
              <a key={phone} href={toPhoneLink(phone)}>
                {phone}
              </a>
            ))}
            <a className={styles.email} href={toMailLink(getSupportEmail())}>
              {getSupportEmail()}
            </a>
            <GeneralChatButton className={styles.generalChatButton} entryPoint={MESSENGER_ENTRY_POINTS.FOOTER} />
          </div>
        </section>
        <section className={styles.address}>
          <div className={styles.titleContainer}>
            <Location className={styles.locationIcon} />
          </div>
          <span>
            {address.street},
            <br /> {address.city},
            <br /> {address.country}
          </span>
        </section>
      </div>
      <FacebookPixel guest={!contact} />
      <GoogleTags contactId={contact ? contact.provider.id : undefined} />
    </footer>
  );
};

export const Footer = withAuth(FooterComponent);
