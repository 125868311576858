import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { ProviderFieldsFragmentDoc } from './providerFields';
export type ProviderFragment = { __typename?: 'Provider', activeChangeRequestId?: number | null, id: number, state: string, competenceFilled?: boolean | null, onboardingCompleted?: boolean | null, publicComment?: string | null, primaryContactId?: number | null, name: string, legalName?: string | null, email?: string | null, vatId?: string | null, notVatPayer: boolean, phone?: string | null, website?: string | null, pincode?: string | null, locale?: string | null, bankAccount?: string | null, bankName?: string | null, bankCode?: string | null, bankAccountHolderName?: string | null, bankCountry?: string | null, isEuropean?: boolean | null, isLocal?: boolean | null, isRfqReady?: boolean | null, poNumberLimit?: number | null, poValueLimit?: number | null, acceptSelfBilling: boolean, freeCapacity: boolean, freeCapacityFreezeUntil?: string | null, financeCheckAt?: string | null, fileUuidIds?: Array<string> | null, limitsComment?: string | null, gstIn?: string | null, performance?: { __typename?: 'Performance', totalOffersAvailable: number, totalOffers: number, urgentCount: number } | null, tags: Array<{ __typename?: 'Tag', id: number, name: string, context: string }>, billingAddress: { __typename?: 'Address', city?: string | null, country?: string | null, zip?: string | null, address?: string | null }, shippingAddress: { __typename?: 'Address', city?: string | null, country?: string | null, zip?: string | null, address?: string | null } };

export const ProviderFragmentDoc = gql`
    fragment provider on Provider {
  activeChangeRequestId
  ...providerFields
  performance {
    totalOffersAvailable
    totalOffers
    urgentCount
  }
  tags {
    id
    name
    context
  }
  billingAddress {
    city
    country
    zip
    address
  }
  shippingAddress {
    city
    country
    zip
    address
  }
}
    ${ProviderFieldsFragmentDoc}`;