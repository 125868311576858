import { IWindow } from 'common/interfaces';
import React, { FC, useEffect } from 'react';

const winInterface = window as IWindow;

interface IProps {
  guest: boolean;
}

const FacebookPixel: FC<IProps> = ({ guest }) => {
  useEffect(() => {
    if (!winInterface.fbq) {
      console.error("Can't init pixel fbq");

      return;
    }

    if (guest) {
      winInterface.fbq('init', '358523682008225');
      winInterface.fbq('init', '905187406642635');
      winInterface.fbq('track', 'PageView');
    } else {
      winInterface.fbq('init', '379725895947661');
      winInterface.fbq('track', 'PageView');
    }
  }, [guest]);

  return <></>;
};

export default FacebookPixel;
