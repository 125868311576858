import { RangePickerProps } from 'antd/lib/date-picker';
import { DATE_F } from 'common/helpers/formtatters';
import { CustomDatePicker } from 'components/CustomDatePicker';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import s from './RangePicker.module.less';

interface Props {
  startDate?: Moment | null;
  endDate?: Moment | null;
  onStartDateChange: (date: Moment | null) => void;
  onEndDateChange: (date: Moment | null) => void;
}

export const RangePicker: FC<Props> = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {
  const { t } = useTranslation();
  const disabledEndDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    return startDate ? startDate.isAfter(current) : false;
  };

  const disabledStartDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    const endDateToDays = endDate?.startOf('day');
    const currentDateToDays = current.startOf('day');

    return endDateToDays ? endDateToDays?.isBefore(currentDateToDays) : false;
  };

  return (
    <div className={s.container}>
      <div>
        <div className={s.label}>{t('calendar.startDate')}</div>
        <CustomDatePicker
          name="startDate"
          format={DATE_F}
          value={startDate}
          onChange={onStartDateChange}
          disabledDate={disabledStartDate}
          getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
        />
      </div>
      <span className={s.separator}>&mdash;</span>
      <div>
        <div className={s.label}>{t('calendar.endDate')}</div>
        <CustomDatePicker
          name="endDate"
          format={DATE_F}
          value={endDate}
          onChange={onEndDateChange}
          disabledDate={disabledEndDate}
          getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
        />
      </div>
    </div>
  );
};
