import { CurrencyEnum } from '__generated__/types';
import { ENV } from 'common/constants/env';

import { EURegionConfig } from './configs/eu';
import { INDIARegionConfig } from './configs/india';
import { TRRegionConfig } from './configs/tr';
import { UKRegionConfig } from './configs/uk';
import { Regions, RegionsData } from './types';

export const RegionsConfig: RegionsData = {
  [Regions.EU]: EURegionConfig,
  [Regions.TR]: TRRegionConfig,
  [Regions.INDIA]: INDIARegionConfig,
  [Regions.UK]: UKRegionConfig,
};

export const CURRENT_REGION = RegionsConfig[ENV.APP_REGION as keyof typeof RegionsConfig]?.region || Regions.EU;
export const CURRENCIES = Object.values(CurrencyEnum);
